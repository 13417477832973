import React, { useEffect, useRef } from 'react';
import Section from '../../../layout/web/Section';
import MainBanner from '../../../components/web/slider/MainBanner';
import MainSectionCard from '../../../components/web/cards/MainSectionCard';
import ServerSectionBg from '../../../assets/images/mainSectionBg.png';
import ServerSectionContent from '../../../assets/images/mainSectionContentImage.png';
import MainSectionRowList from '../../../components/web/lists/MainSectionRowList';
import SectionTypography from '../../../components/web/texts/SectionTypography';
import InfiniteScrollImage from '../../../components/web/slider/InfiniteScrollImage';
import {
	paymentSectionItems,
	ServerSectionItems,
} from '../../../utility/web/items';
import styled from '@emotion/styled';
import { useMediaQuery } from '@mui/material';

const Main = () => {
	const firstSectionTitleRef = useRef(null);
	const thirdSectionTitleRef = useRef(null);
	const fourthSectionRef = useRef(null);
	const sm = useMediaQuery('(max-width:800px)');
	const callback = (entries, io) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				entry.target.classList.add('active');
				io.unobserve(entry.target);
			}
		});
	};
	const sections = new IntersectionObserver(callback, {
		threshold: [sm ? 0.1 : 0.5],
	});
	useEffect(() => {
		if (firstSectionTitleRef.current)
			sections.observe(firstSectionTitleRef.current);
		if (thirdSectionTitleRef.current)
			sections.observe(thirdSectionTitleRef.current);
		if (fourthSectionRef.current) sections.observe(fourthSectionRef.current);
		return () => {
			if (firstSectionTitleRef.current)
				sections.unobserve(firstSectionTitleRef.current);
			if (thirdSectionTitleRef.current)
				sections.unobserve(thirdSectionTitleRef.current);
			if (fourthSectionRef.current)
				sections.unobserve(fourthSectionRef.current);
		};
	}, []);
	return (
		<div className={'w-full h-auto'}>
			<MainBanner />
			<Section className={'bg-darkF9'}>
				<FadeInYSection maxSize>
					<div
						ref={firstSectionTitleRef}
						className={
							'w-full h-auto flex flex-col items-center justify-center pt-[120px] pb-[116px] md:py-[80px] sm:py-[65px] xs:py-[50px] fold:py-[50px]'
						}>
						<div
							className={
								'mb-[70px] md:mb-[60px] sm:mb-[50px] xs:mb-[40px] fold:mb-[40px] text-center '
							}>
							<div
								className={`text-dark1A text-heading1 md:text-heading2 sm:text-heading3 xs:text-heading3 xs:leading-[32px] fold:text-heading3 fold:leading-[32px] flex flex-row items-center justify-center sm:flex-col xs:flex-col fold:flex-col pb-[20px] xs:pb-[15px] fold:pb-[15px] fade-in-up`}>
								<span>
									결제는 당연히<span>&nbsp;</span>
								</span>
								<span>쉽고 간편해야 합니다.</span>
							</div>
							<div
								className={`text-dark49 text-bodyLarge md:text-bodyMedium sm:text-bodyMedium xs:text-bodySmall xs:leading-[30px] fold:text-bodySmall fold:leading-[30px] fade-in-up`}>
								<div>On/Off 결제 시스템을 갖춘 기업으로</div>
								<div className={'fold:flex fold:flex-col'}>
									<span>
										고객 성공에 꼭 필요한<span>&nbsp;</span>
										<span className={'xs:hidden fold:hidden'}>
											결제 <span> </span>
										</span>
										서비스를
									</span>
									<span> </span>
									<span>제공하고 있습니다.</span>
								</div>
							</div>
						</div>
						<div
							className={
								'w-full flex flex-row items-center justify-between sectionCardSm:flex-col xs:flex-col fold:flex-col sectionCardSm:gap-[30px] xs:gap-[30px] fold:gap-[30px] '
							}>
							{paymentSectionItems.map((item, index) => {
								return (
									<MainSectionCard item={item} index={index} key={index} />
								);
							})}
						</div>
					</div>
				</FadeInYSection>
			</Section>
			<Section>
				<FadeInXContainer
					ref={thirdSectionTitleRef}
					className={
						'relative max-h-[1000px] min-h-[544px]  flex flex-row sm:flex-col xs:flex-col fold:flex-col lg:items-start items-center justify-end py-[177px] md:py-[140px] sm:py-[65px] xs:py-[50px] fold:py-[50px] overflow-hidden'
					}>
					<img
						src={ServerSectionBg}
						alt=''
						className={'bg-main absolute top-0 left-0 h-full w-full -z-[1] '}
					/>
					<Section
						maxSize
						className={
							'flex flex-row items-start h-full justify-end md:justify-between  whitespace-nowrap md:mr-[80px] sm:mb-[70px] xs:mb-[50px] fold:mb-[40px] lg:w-[70%] md:w-[30%] md:pl-[10px] lg:pt-[170px] transition-all'
						}>
						<div
							className={
								'transition-all h-full w-full lg:w-[70%] text-white flex flex-col justify-center sm:items-center sm:text-center xs:items-center xs:text-center fold:text-center '
							}>
							<div
								className={
									'text-heading1 flex flex-col whitespace-nowrap mb-4 md:text-heading3 sm:text-heading2 xs:text-heading3 xs:leading-[32px] fold:text-heading3 fold:leading-[32px] fade-in-left'
								}>
								<span>어떠한 상황에도</span>
								<span>대처 가능한 통합 결제 서비스</span>
								<span>결제는 언제나 안전하니까요!</span>
							</div>
							<div
								className={
									'text-bodyLarge md:text-bodyNormal sm:text-bodyMedium xs:text-bodySmall fold:text-bodySmall  sm:font-[500] xs:font-[500] fold:font-[500] fade-in-left'
								}>
								P&S는 대면/비대면 결제를 모두 지원합니다.
							</div>
						</div>
					</Section>
					<div
						className={
							'w-full h-auto md:w-[50%] max-w-[1078px] md:max-w-[70%]  flex items-center justify-end -translate-y-[10px]'
						}>
						<div className={'w-full xs:pl-[30px] fold:pl-[30px]'}>
							<img
								src={ServerSectionContent}
								alt=''
								className={'w-full h-full  fade-in-up'}
							/>
						</div>
					</div>
				</FadeInXContainer>
			</Section>
			<Section>
				<Section
					className={
						'relative  pt-[120px] md:pt-[95px] sm:pt-[70px] xs:pt-[50px] fold:pt-[50px] transition-all'
					}
					maxSize>
					<FourthSection ref={fourthSectionRef}>
						<div className={'text-center xs:mb-[40px] fold:mb-[40px]'}>
							<SectionTypography
								title={'P&S만의 경쟁력'}
								className={'fade-in-up'}
							/>
							<div
								className={
									'text-dark49 text-bodyLarge md:text-bodyMedium sm:flex-col sm:text-bodySmall sm:leading-[30px] xs:flex-col xs:text-bodySmall xs:leading-[30px] fold:flex-col fold:text-bodySmall fold:leading-[30px] sub-fade-in-up'
								}>
								앞서가는 기술력과 고객 맞춤{' '}
								<br className={'hidden xs:block fold:block'} /> 솔루션으로 결제
								시장을 선도합니다.
							</div>
						</div>
						<MainSectionRowList items={ServerSectionItems} />
					</FourthSection>
				</Section>
			</Section>
			<Section
				className={
					'bg-darkF9 pt-[120px] md:pt-[100px] sm:pt-[80px] xs:pt-[50px] fold:pt-[50px] '
				}>
				<Section maxSize>
					<div
						className={
							'flex items-center justify-center text-center gap-[10px] mb-[120px] md:mb-[100px] sm:mb-[80px] xs:mb-[60px] fold:mb-[40px] text-dark1A'
						}>
						<div
							className={
								'fade-in-up text-heading1 md:text-heading2 sm:text-heading3 xs:text-heading3 fold:text-heading3 xs:leading-[32px] fold:leading-[32px]'
							}>
							P&S와
							<br className={'hidden xs:block fold:block'} /> 함께하고 있는
							기업들
						</div>
					</div>
				</Section>
				<Section>
					<InfiniteScrollImage />
				</Section>
			</Section>
		</div>
	);
};

export default Main;

const FadeInYSection = styled(Section)`
	.fade-in-up {
		opacity: 0;
		transform: translateY(100px);
		transition:
			opacity 1s,
			transform 0.7s;
	}
	.active .fade-in-up {
		opacity: 1;
		transform: translateY(0);
	}
`;
const FadeInXContainer = styled.div`
	.fade-in-left {
		opacity: 0.6;
		transform: translateX(-100%);
		transition:
			opacity 1s,
			transform 1.3s;
	}
	.fade-in-up {
		opacity: 0.6;
		transform: translateY(100%);
		transition:
			opacity 1s,
			transform 1.3s;
	}
	.fade-in-right {
		opacity: 0;
		transform: translateX(100%);
		transition:
			opacity 1s,
			transform 1.3s;
	}
	.fade-in-down {
		opacity: 0;
		transform: translateY(-100px);
		transition:
			opacity 1s,
			transform 1s;
	}
	&.active .fade-in-left,
	&.active .fade-in-right,
	&.active .fade-in-up,
	&.active .fade-in-down {
		opacity: 1;
		transform: translateX(0);
	}
`;
const FourthSection = styled.div`
	.fade-in-up {
		opacity: 0;
		transform: translateY(100px);
		transition:
			opacity 1s,
			transform 0.7s;
	}
	.sub-fade-in-up {
		opacity: 0;
		transform: translateY(100px);
		transition:
			opacity 1s,
			transform 1s;
	}

	&.active .fade-in-up,
	&.active .sub-fade-in-up {
		opacity: 1;
		transform: translateY(0);
	}
`;
