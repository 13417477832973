export const dropdownItems = [
	{
		title: '회사 소개',
		link: '/company/greeting',
		items: [
			{
				subTitle: 'CEO 인사말',
				link: '/company/greeting',
			},
			{
				subTitle: '오시는 길',
				link: '/company/directions',
			},
		],
	},
	{
		title: '서비스 안내',
		link: '/service/payment',
		items: [
			{
				subTitle: '전자결제 서비스',
				link: '/service/payment',
			},
			{
				subTitle: '플랫폼 서비스',
				link: '/service/platform',
			},
			{
				subTitle: '부가 서비스',
				link: '/service/additional',
			},
		],
	},
	{
		title: '서비스 신청',
		link: '/apply/procedure',
		items: [
			{
				subTitle: '서비스 신청절차',
				link: '/apply/procedure',
			},
			{
				subTitle: '서비스 신청',
				link: '/apply/service',
			},
		],
	},
	{
		title: '자료실',
		link: '/referenceRoom',
		items: [
			{
				subTitle: '자료실',
				link: '/referenceRoom',
			},
			{
				subTitle: '연동 매뉴얼',
				link: 'https://www.baumpns-dev.com',
			},
			// {
			//   subTitle: '통신개통',
			//   link: '/referenceRoom/commencementCommunication',
			// },
			// {
			//   subTitle: 'BizOne ERP',
			//   link: '/referenceRoom/bizOneErp',
			// },
		],
	},
	{
		title: '고객센터',
		link: '/customerService/frequentlyAskedQuestion/paymentManagement',
		items: [
			{
				subTitle: '자주묻는 질문',
				link: '/customerService/frequentlyAskedQuestion/paymentManagement',
			},
			{
				subTitle: '공지사항',
				link: '/customerService/notice',
			},
			{
				subTitle: '문의하기',
				link: '/customerService/question',
			},
		],
	},
];
