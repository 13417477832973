import React, { useEffect } from 'react';
import Content from './Content';
import Footer from './Footer';
import { Outlet, useLocation } from 'react-router-dom';
import Header from './Header';
import styled from '@emotion/styled';
import CustomScroll from './CustomScroll';

const Layout = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <LayoutContainer className={'max-w-screen-max'}>
      <Header />
      <Content>
        <Outlet />
      </Content>
      <Footer />
      <CustomScroll />
    </LayoutContainer>
  );
};

export default Layout;

const LayoutContainer = styled.div`
  width: 100%;
  min-width: 280px;
  height: auto;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
`;
